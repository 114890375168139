import img_1 from '../assets/image/1.jpg';
import img_2 from '../assets/image/2.jpg';
import img_3 from '../assets/image/3.jpg';
import img_4 from '../assets/image/4.jpg';
import img_5 from '../assets/image/5.jpg';
import img_6 from '../assets/image/6.jpg';
import img_7 from '../assets/image/7.jpg';
import img_8 from '../assets/image/8.jpg';
import img_9 from '../assets/image/9.jpg';
import img_10 from '../assets/image/10.jpg';
import img_11 from '../assets/image/11.jpg';
import img_12 from '../assets/image/12.jpg';
import img_13 from '../assets/image/13.jpg';
import img_14 from '../assets/image/14.jpg';
import img_15 from '../assets/image/15.jpg';


function imgs() {

    const imges = [img_1, img_2, img_3, img_4, img_5 , img_6, img_7, img_8, img_9, img_10, img_11, img_12, img_13, img_14, img_15];
        // , img_6, img_7, img_8];

    return imges

}

export  {imgs,};

import React, { useState } from 'react';
import data from '../assets/image_data.json';
import pinIcon from '../assets/location-pin.png';
import brideAccountData from '../assets/bride_account_number_data.json';
import groomAccountData from '../assets/groom_account_number_data.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container as MapDiv, NaverMap, Marker, useNavermaps} from 'react-naver-maps';
import '../App.css';
import ImageModal from '../components/imageModal';
import AccountModal from '../components/accountModal';
import mainImg from '../assets/image/main.jpg';
import inviteImg from '../assets/image/invitation.png';
import infoImg from '../assets/image/info.png';
import calImg from '../assets/image/calendar.png';
import galImg from '../assets/image/gallery.png';
import locImg from '../assets/image/location.png';
import parkImg from '../assets/image/parking.png';
import {imgs} from './Gallery'
import { Sakura } from './Sakura';



function Bride() {

  // state for image modal
  const [clickedImg, setClickedImg] = useState('');
  const [currentIndex, setCurrentIndex] = useState();
  // state for account modal
  const [ clickedAccountData, setClickedAccountData ] = useState(null);
  const [ copiedAccount, setCopiedAccount ] = useState(null);

  const navermaps = useNavermaps()

  
  const handleClick = (item, index) => {

    setCurrentIndex(index);
    setClickedImg(item);
  };
  const accountClick = (account_data) => {
    setClickedAccountData(account_data.data);
  };

  const handleRotationRight = () => {
    const totalLength = imgs().length;  
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = imgs()[0];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;

    const newUrl = imgs()?.filter((item) => {
      return imgs().indexOf(item) === newIndex;
      });


    const newItem = newUrl[0];

    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };
  
  const handleRotationLeft = () => {

    const totalLength = imgs().length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = imgs()[totalLength-1];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = imgs().filter((item) => {
      return imgs().indexOf(item) === newIndex;
      });
    const newItem = newUrl[0];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  new Sakura('body', {
    colors: [
      {
        gradientColorStart: 'rgba(255, 183, 197, 0.9)',
        gradientColorEnd: 'rgba(255, 197, 208, 0.9)',
        gradientColorDegree: 120,
      },
      // {
      //   gradientColorStart: 'rgba(255,189,189)',
      //   gradientColorEnd: 'rgba(227,170,181)',
      //   gradientColorDegree: 120,
      // },
      // {
      //   gradientColorStart: 'rgba(212,152,163)',
      //   gradientColorEnd: 'rgba(242,185,196)',
      //   gradientColorDegree: 120,
      // },
    ],
    delay: 200,
  });

  return (
      <div className='main container'>
        <div className="row justify-content-md-center">
        <div className="col col-md-2 col-lg-3">
          <div className="col-md">
            
            <div className='mainsection'>
              <div>
                <img src={mainImg} className='main-image' alt='t'></img>
              </div>
              {/* <div className='mainsection-text'>
                <div className='mainsection-text-1'>결혼식에 초대합니다</div>
                <div className='mainsection-text-2'>
                  안세준 <span className='text2-inner'> & </span> 조은희
                </div>
                <div className='mainsection-text-3'>2024. 05. 25 토요일 오후 1시<br/>서울 신도림 더 링크호텔, 2F 더 링크홀</div>
              </div> */}
            </div>
            {/* <div className='invitation-section'> */}
            <div>
            <img src={inviteImg} className='main-image' alt='t'></img>
            </div>
            <div>
            <img src={infoImg} className='main-image' alt='t'></img>
            <img src={calImg} className='main-image' alt='t'></img>
            </div>
              {/* <div className='invitation-section-text1'>INVITATION</div>
              <div className='invitation-section-text2'>
                    저희 두 사람이 사랑과 믿음으로<br/>
                    한 가정을 이루게 되었습니다.<br/>
                    바쁘시더라도 부디 오셔서<br/>
                    저희의 앞날을 축복해 주시고<br/>
                    격려해 주시면 감사하겠습니다.
              </div>
              <div className='invitation-section-text3'>
                故안재근・김영숙<span className='text3-inner'>의 아들</span> 안세준
              </div>
              <div className='invitation-section-text3'>
                조성관・이미녀<span className='text3-inner'>의 딸</span> 조은희
              </div> */}
            {/* </div> */}
            <div className='gallery-section'>
              <div className='gallery-section-text'>
              <img src={galImg} className='main-image' alt='t'></img>
              </div>
            </div>
            <div>
              <div className='gallery-image-list-wrapper row'>
                  {imgs()?.map((item, index) => (
                     <div key={index} className='col-4'>
                     <img className='gallery-image' src={item} alt={index} onClick={()=> handleClick(item, index)}/>
                   </div>     
                    // <div key={index} className='col-4'>
                    //   <img className='gallery-image' src={item.thumb_image_link} alt={item.text} onClick={()=> handleClick(item, index)}/>
                    // </div>
                  ))}
              </div>            
              {clickedImg && <ImageModal 
              clickedImg={clickedImg}
              handleRotationRight={()=>handleRotationRight()}
              handleRotationLeft={()=>handleRotationLeft()}
              setClickedImg={setClickedImg}
              />}
            </div>
            <div className='location-section'>
              {/* <div className='location-section-text1'>
                LOCATION
              </div> */}
            </div>
            <div className='location-map-section'>
              {/* <MapDiv
                style={{
                  width: '100%',
                  height: '350px'
                }}
              >
                <NaverMap 
                  defaultCenter={new navermaps.LatLng(37.44865592343993,126.95097244672262)}
                  defaultZoom={16}>
                  <Marker 
                  position={new navermaps.LatLng(37.44865592343993,126.95097244672262)} 
                  icon={
                    {
                      url : pinIcon,
                      size : new navermaps.Size(64,64)
                    }
                  }/>
                </NaverMap>
              </MapDiv> */}
            </div>
            <div className='location-info-section'>
            <img src={locImg} className='main-image' alt='t'></img>
                {/* <div className='location-info-section-text1'>신도림 더 링크호텔 2F, 링크홀</div>
                <div className='location-info-section-text2'>
                    더링크호텔 서울<br/>
                    서울특별시 구로구 경인로 610(신도림동 413-9)<br/>
                    Tel. 02-852-5000
                </div> */}
            </div>
            <div className='location-how-publictrans-section'>
            <img src={parkImg} className='main-image' alt='t'></img>
              {/* <div className='location-how-publictrans-section-text1'>대중교통</div>
              <ol className='location-how-publictrans-section-list'>
                <li>2호선 서울대입구역 3번 출구 → 5511,5513 버스 → 제2공학관(종점) 하차</li>
                <li>2호선 낙성대역 4번 출구 → 관악02 마을버스 → 제2공학관(종점) 하차</li>
                <li>신림선 관악산역 1번 출구 → 5511,5516 버스 → 제2공학관(종점) 하차</li>
              </ol> */}
            </div>
            {/* <div className='location-how2-section'> */}
              {/* <div className='location-how2-section-text1'>자가용</div> */}
              {/* <div className='location-how2-section-text2'>
                네비게이션 이용 시 “이라운지 서울대점”을 입력하세요. (주차 2시간 무료)
              </div> */}
            {/* </div> */}
            <div className='congratulatory-section'>
              <div className='congratulatory-section-text'>마음 전하실 곳</div>
                <div 
                  className='congratulatory-section-btn' 
                  onClick={() => accountClick(groomAccountData)}>신랑측 계좌번호</div>
                <div 
                  className='congratulatory-section-btn'
                  onClick={() => accountClick(brideAccountData)}>신부측 계좌번호</div>
            </div>
            {clickedAccountData && <AccountModal 
              clickedAccountData={clickedAccountData}
              setClickedAccountData={setClickedAccountData}
              copiedAccount={copiedAccount}
              setCopiedAccount={setCopiedAccount}
              />}
          </div>

          <div className="col col-md-2 col-lg-3">
          </div>
        </div>
        </div>
      </div>

    
  );
}

export default Bride;
